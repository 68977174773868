var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[(!_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"card-text"},[_c('span',{staticClass:"mx-1 pointer float-right",on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('fa',{staticClass:"text-warning",attrs:{"icon":"pencil-alt"}})],1),_c('div',{},[_vm._v(" "+_vm._s(_vm.item.inventory.code)+" ("+_vm._s(_vm.item.inventory.name)+") ")]),(_vm.transferItems.length > 0)?_c('div',{staticClass:"mt-2"},[_c('small',[_vm._v(" ไปยังสินค้า: "),_c('ul',{staticClass:"my-0"},_vm._l((_vm.transferItems),function(child){return _c('li',{key:child.id},[_c('div',{staticClass:"text-warning"},[_vm._v("สินค้า: "+_vm._s(child.inventory.code)+" ("+_vm._s(child.inventory.name)+")")]),_c('div',{staticClass:"text-info"},[_vm._v("บัญชี: "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+")")]),_c('div',{},[_c('span',{staticClass:"text-success"},[_vm._v(" จำนวน: "+_vm._s(child.qty)+" ")]),_vm._v(" สัดส่วน: "+_vm._s(child.amountRatio)+" ")])])}),0)])]):_vm._e(),(_vm.transferExpenses.length > 0)?_c('div',{staticClass:"mt-2"},[_c('small',[_vm._v(" ไปยังค่าใช้จ่าย: "),_c('ul',{staticClass:"my-0"},_vm._l((_vm.transferExpenses),function(child){return _c('li',{key:child.id},[_c('div',{staticClass:"text-info"},[_vm._v("บัญชี: "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+")")]),_c('div',{},[_vm._v("สัดส่วน: "+_vm._s(child.amountRatio))])])}),0)])]):_vm._e()])]):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('div',{staticClass:"form-row"},[_c('DocConfigFormInventory',{ref:"inventory",staticClass:"col-12",attrs:{"docType":_vm.docType,"templateType":_vm.templateType,"select":"id","types":_vm.menu.inventoryTypes,"validations":[
            {text: 'required!', value: _vm.$v.formData.inventoryId.$dirty && !_vm.$v.formData.inventoryId.required}
          ]},model:{value:(_vm.formData.inventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "inventoryId", $$v)},expression:"formData.inventoryId"}})],1),(_vm.item.id)?[_c('ul',{staticClass:"nav nav-pills justify-content-center mb-3"},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.value,staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();_vm.selectedTab = tab.value}}},[_c('a',{staticClass:"nav-link",class:{'active': _vm.selectedTab === tab.value},attrs:{"href":"#"}},[_vm._v(" "+_vm._s(tab.text)+" ")])])}),0),(_vm.selectedTab === 'transferItem')?_c('div',[_c('div',{staticClass:"form-row"},[(_vm.menu.inventoryTypes.length > 0)?_c('DocConfigFormInventory',{staticClass:"col-12",attrs:{"select":"id","types":_vm.menu.inventoryTypes,"docType":_vm.docType,"templateType":_vm.templateType,"validations":[
                {text: 'required!', value: _vm.$v.transferItemFormData.inventoryId.$dirty && _vm.$v.transferItemFormData.inventoryId.$error}
              ]},model:{value:(_vm.transferItemFormData.inventoryId),callback:function ($$v) {_vm.$set(_vm.transferItemFormData, "inventoryId", $$v)},expression:"transferItemFormData.inventoryId"}}):_vm._e(),_c('DocConfigFormAccount',{staticClass:"col-12 col-sm-6",attrs:{"label":"บัญชี","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
                {text: 'required!', value: _vm.$v.transferItemFormData.accountId.$dirty && _vm.$v.transferItemFormData.accountId.$error}
              ]},model:{value:(_vm.transferItemFormData.accountId),callback:function ($$v) {_vm.$set(_vm.transferItemFormData, "accountId", $$v)},expression:"transferItemFormData.accountId"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-3",attrs:{"label":"จำนวน","placeholder":"จำนวน","precision":2,"validations":[
                {text: 'required!', value: _vm.$v.transferItemFormData.qty.$dirty && !_vm.$v.transferItemFormData.qty.required}
              ]},model:{value:(_vm.transferItemFormData.qty),callback:function ($$v) {_vm.$set(_vm.transferItemFormData, "qty", $$v)},expression:"transferItemFormData.qty"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-3",attrs:{"label":"สัดส่วนทางบัญชี","placeholder":"0.00 ถึง 1.00","precision":2,"validations":[
                {text: 'required!', value: _vm.$v.transferItemFormData.amountRatio.$dirty && !_vm.$v.transferItemFormData.amountRatio.required},
                {text: 'จำนวนมากกว่า 0', value: _vm.$v.transferItemFormData.amountRatio.$dirty && !_vm.$v.transferItemFormData.amountRatio.minValue}
              ]},model:{value:(_vm.transferItemFormData.amountRatio),callback:function ($$v) {_vm.$set(_vm.transferItemFormData, "amountRatio", $$v)},expression:"transferItemFormData.amountRatio"}})],1),_c('button',{staticClass:"btn btn-success mb-3",attrs:{"type":"button"},on:{"click":_vm.addTransferItem}},[_vm._v(" เพิ่ม ")]),(_vm.transferItems.length > 0)?_c('div',{staticClass:"mb-3"},[_c('small',[_c('ul',{staticClass:"my-0"},_vm._l((_vm.transferItems),function(child){return _c('li',{key:child.id},[_c('span',{staticClass:"pointer mr-1",on:{"click":function($event){return _vm.destroyData(child.id, _vm.item.id)}}},[_c('fa',{staticClass:"text-danger",attrs:{"icon":"trash"}})],1),_c('div',{},[_vm._v("สินค้า: "+_vm._s(child.inventory.code)+" ("+_vm._s(child.inventory.name)+")")]),_c('div',{},[_vm._v("บัญชี: "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+")")]),_c('div',{},[_vm._v(" จำนวน: "+_vm._s(child.qty)+" สัดส่วน: "+_vm._s(child.amountRatio)+" ")])])}),0)])]):_vm._e()]):_vm._e(),(_vm.selectedTab === 'transferExpense')?_c('div',[_c('div',{staticClass:"form-row"},[_c('DocConfigFormAccount',{staticClass:"col-12 col-sm-8",attrs:{"label":"บัญชี","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
                {text: 'required!', value: _vm.$v.transferExpenseFormData.accountId.$dirty && _vm.$v.transferExpenseFormData.accountId.$error}
              ]},model:{value:(_vm.transferExpenseFormData.accountId),callback:function ($$v) {_vm.$set(_vm.transferExpenseFormData, "accountId", $$v)},expression:"transferExpenseFormData.accountId"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-4",attrs:{"label":"สัดส่วนทางบัญชี","placeholder":"0.00 ถึง 1.00","precision":2,"validations":[
                {text: 'required!', value: _vm.$v.transferExpenseFormData.amountRatio.$dirty && !_vm.$v.transferExpenseFormData.amountRatio.required},
                {text: 'จำนวนมากกว่า 0', value: _vm.$v.transferExpenseFormData.amountRatio.$dirty && !_vm.$v.transferExpenseFormData.amountRatio.minValue}
              ]},model:{value:(_vm.transferExpenseFormData.amountRatio),callback:function ($$v) {_vm.$set(_vm.transferExpenseFormData, "amountRatio", $$v)},expression:"transferExpenseFormData.amountRatio"}})],1),_c('button',{staticClass:"btn btn-success mb-3",attrs:{"type":"button"},on:{"click":_vm.addTransferExpense}},[_vm._v(" เพิ่ม ")]),(_vm.transferExpenses.length > 0)?_c('div',{staticClass:"mb-3"},[_c('small',[_c('ul',{staticClass:"my-0"},_vm._l((_vm.transferExpenses),function(child){return _c('li',{key:child.id},[_c('span',{staticClass:"pointer mr-1",on:{"click":function($event){return _vm.destroyData(child.id, _vm.item.id)}}},[_c('fa',{staticClass:"text-danger",attrs:{"icon":"trash"}})],1),_c('div',{},[_vm._v("บัญชี: "+_vm._s(child.account.code)+" ("+_vm._s(child.account.name)+")")]),_c('div',{},[_vm._v("สัดส่วน: "+_vm._s(child.amountRatio))])])}),0)])]):_vm._e()]):_vm._e()]:_vm._e(),(_vm.isForm)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" เพิ่ม ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" แก้ไข ")]):_vm._e(),(_vm.toggle)?_c('button',{staticClass:"btn btn-primary ml-2",attrs:{"type":"button"},on:{"click":_vm.cancelData}},[_vm._v(" ยกเลิก ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-danger ml-2 float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyData(_vm.item.id)}}},[_vm._v(" ลบ ")]):_vm._e()],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }